import { QueryFunctionContext } from "react-query";
import { baseFetch, HttpMethods } from "../../api/baseFetch";

export enum CurrencyCode {
  USD = "USD",
  EUR = "EUR",
}

export enum TransferStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  SOURCE_ACCOUNT_NOT_FOUND = "SOURCE_ACCOUNT_NOT_FOUND",
  ACCOUNT_SUSPENDED = "ACCOUNT_SUSPENDED",
}

export enum CustomStatus {
  ERROR = "ERROR",
}

export interface BankAccount {
  currency?: string;
  currency_description?: string;
  local_branch_identifier?: string;
  secondary_branch_identifier?: string;
  bank_name?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  postal_code?: string;
  country?: string;
  country_code?: string;
  account_name?: string;
  iban?: string;
  swift?: string;
  available_balance?: number;
  bank_account_nr?: string;
  aba_routing_nr?: string;
  address?: string;
}
export interface PaymentRetrieveResponse {
  bank_account_number?: string;
  payment_reference?: string;
  bank_details: {
    batch_id: string;
    ck_number: string;
    amount: number;
    currency_code: CurrencyCode;
    created_at: string;
    order_reference: string;
    status: TransferStatus;
    source_account: string;
    destination_account: string;
    bank_accounts: BankAccount[];
    source_business_id?: string;
    destination_business_id?: string;
    description?: string;
  };
  order_details: {
    order_amount: string;
    order_currency: CurrencyCode;
    type: string;
    details: {
      type: string;
      reference: string;
      amount: string;
      currency_code: CurrencyCode;
      source: {
        business_id: string;
      };
      destination: {
        business_id: string;
      };
      description: string;
      createdAt: string;
    }[];
    order_size: number;
    order_reference: string;
  };
}

export type PaymentRequestParams = {
  jws: string;
};

export type PaymentUpdateRequestParams = {
  bank_account_number: string;
  payment_reference: string;
} & PaymentRequestParams;

export const paymentDetails = async (
  params: QueryFunctionContext<[string, PaymentRequestParams]>
) => {
  const [_, { jws }] = params.queryKey;

  if (!jws) {
    return;
  }

  return await baseFetch<PaymentRequestParams, PaymentRetrieveResponse>({
    url: "/retrieve",
    method: HttpMethods.post,
    body: JSON.stringify({ jws }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface PaymentApproveResponse {
  "Transfer batch with ID sent to processing": string;
}

export const paymentApprove = async (params: PaymentRequestParams) => {
  const { jws } = params;

  if (!jws) {
    return;
  }

  return await baseFetch<PaymentRequestParams, PaymentApproveResponse>({
    url: "/approve",
    method: HttpMethods.post,
    body: JSON.stringify({ jws }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const paymentUpdate = async (params: PaymentUpdateRequestParams) => {
  const { jws, bank_account_number, payment_reference } = params;

  if (!bank_account_number || !payment_reference || !jws) {
    return;
  }

  return await baseFetch<PaymentRequestParams, string>({
    url: "/update",
    method: HttpMethods.post,
    body: JSON.stringify({ jws, bank_account_number, payment_reference }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
