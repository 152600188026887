import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as RowIcon } from "../../assets/images/row-icon.svg";
import { ReactComponent as CastleIcon } from "../../assets/images/castle.svg";
import { ReactComponent as ArtymysIcon } from "../../assets/images/artymys.svg";
import { Button } from "../ui/Button";

export const Header = ({ onClickBack }: { onClickBack?: () => void }) => {
  const { t } = useTranslation();

  return (
    <header className={"flex flex-col w-full md:flex-row md:justify-between"}>
      <div className={"flex w-48"}>
        {onClickBack && (
          <Button
            className={"uppercase px-4 py-0 md:px-0"}
            buttonType={"link"}
            buttonPrefix={<RowIcon />}
            onClick={onClickBack}
          >
            {t("common.buttons.back")}
          </Button>
        )}
      </div>
      <ArtymysIcon className={"mx-auto mt-4 md:mt-0"} style={{ width: "250px" }} />
      <div className={"flex w-48 justify-center mt-4 md:mt-0 md:justify-end"}>
        <div className={"flex items-center text-gray-200"}>
          <span className={"mr-4"}>
            <CastleIcon />
          </span>
          <span className={"uppercase"}>{t("header.secureCheckout")}</span>
        </div>
      </div>
    </header>
  );
};
