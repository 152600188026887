import { useTranslation } from "react-i18next";
import { Title } from "../Title";
import { ContentModal } from "../ContentModal";
import { Button } from "../../../../components/ui/Button";
import { ReactComponent as WarningIcon } from "../../../../assets/images/warning.svg";

import { PAYALL_URL } from "../../../../core/routes/paths";
import { Header } from "../../../../components/layout/Header";
import React from "react";

export const Error = () => {
  const { t } = useTranslation();

  const handleContinue = () => {
    window.location.replace(PAYALL_URL);
  };

  return (
    <>
      <Header />
      <div className={"flex-grow my-20"}>
        <div>
          <Title>{t("content.error.title")}</Title>
          <ContentModal className={"py-14"}>
            <div className={"flex justify-center items-center flex-col"}>
              <WarningIcon className={"mb-8"} />
              <span>{t("content.error.modal.text")}</span>
            </div>
          </ContentModal>
          <div className={"flex justify-center mt-20"}>
            <Button onClick={handleContinue} className={"uppercase"}>
              {t("common.buttons.continue")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
