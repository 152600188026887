import { FC } from "react";

interface Props {
  isLoading: boolean;
}

export const Loader: FC<Props> = ({ isLoading, children }) => {
  return (
    <div className={"flex items-center justify-center w-full h-full"}>
      {isLoading ? (
        <div className={"loader"} />
      ) : (
        <div className={"w-full"}>{children}</div>
      )}
    </div>
  );
};
