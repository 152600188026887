import { HorizontalDivider } from "../ui/HorizontalDivider";
import { ReactComponent as PayallIcon } from "../../assets/images/payall.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as SecureIcon } from "../../assets/images/secure-ssl.svg";
import { ReactComponent as AesBitIcon } from "../../assets/images/aes-256-bit.svg";
import { ReactComponent as PcsDssIcon } from "../../assets/images/pcs-dss.svg";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <div>
      <HorizontalDivider className={"my-5"} />
      <div
        className={
          "flex flex-col justify-between md:items-center md:flex-row-reverse px-4 md:px-0"
        }
      >
        <div className={"mx-auto pb-4 md:mx-0 md:pb-0"}>
          <div className={"flex items-center emulated-flex-gap"}>
            <SecureIcon />
            <AesBitIcon />
            <PcsDssIcon />
          </div>
        </div>
        <div className={"flex items-center"}>
          <div className={"mr-2"}>
            <PayallIcon />
          </div>
          <div className={"text-xs text-gray-400"}>
            <div>{t("footer.powerBy")}</div>
            <div>{t("footer.payall")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
