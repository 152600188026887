import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Title } from "../Title";
import { ContentModal } from "../ContentModal";
import { TableRow } from "../TableRow";
import { HorizontalDivider } from "../../../../components/ui/HorizontalDivider";
import { Button } from "../../../../components/ui/Button";
import { balanceFormatter } from "../../../../core/helpers/numberFormatters";
import { PaymentContext } from "../../PaymentContext";
import { PAYALL_URL } from "../../../../core/routes/paths";
import { BankAccount, CurrencyCode } from "../../PaymentServices";
import { Header } from "../../../../components/layout/Header";

const getAddress = (account: BankAccount) => {
  const address = [];
  account.bank_name && address.push(account.bank_name);
  account.address1 && address.push(account.address1);
  account.address2 && address.push(account.address2);
  account.address3 && address.push(account.address3);
  account.postal_code && address.push(account.postal_code);
  account.city && address.push(account.city);
  account.country && address.push(account.country);
  return address.join(", ");
};

export const InsufficientFunds = () => {
  const { t } = useTranslation();
  const { transferDetails } = useContext(PaymentContext);

  const handleOk = () => {
    window.location.replace(PAYALL_URL);
  };

  return (
    <>
      <Header />
      <div className={"flex-grow my-20"}>
        <div>
          <div
            className={
              "flex flex-col justify-center items-center w-full max-w-3xl mx-auto px-4"
            }
          >
            <Title className={"text-pink-100"}>
              {t("content.insufficientFunds.title")}
            </Title>
            <span className={"text-center mb-12"}>
              {/*{t("content.insufficientFunds.text1")}&nbsp;*/}
              {/*{transferDetails?.bank_details.available_balance && (*/}
              {/*  <strong>*/}
              {/*    {t("content.insufficientFunds.text2")}&nbsp;*/}
              {/*    {balanceFormatter(*/}
              {/*      transferDetails.bank_details.available_balance,*/}
              {/*      transferDetails.bank_details.currency_code*/}
              {/*    )}*/}
              {/*    .*/}
              {/*  </strong>*/}
              {/*)}*/}
              <br />
              {t("content.insufficientFunds.text3")}
            </span>
          </div>
          <ContentModal
            className={"py-14 mb-4"}
            title={t("content.bankDetails.orderTitle")}
          >
            <TableRow
              text={t("content.bankDetails.orderReferenceID")}
              value={transferDetails?.bank_details.order_reference}
            />
          </ContentModal>
          <ContentModal
            className={"py-14"}
            title={t("content.bankDetails.title")}
          >
            {transferDetails?.bank_details.bank_accounts.map((account) => (
              <Fragment key={account.bank_account_nr}>
                <TableRow
                  text={t("content.bankDetails.accountName")}
                  value={account?.account_name}
                />
                <TableRow
                  text={t("content.bankDetails.bankAddress")}
                  value={getAddress(account)}
                />
                <TableRow
                  text={t("content.bankDetails.bankCode")}
                  value={account.swift}
                />
                <TableRow
                  text={t("content.bankDetails.iban")}
                  value={account.iban}
                />
                <TableRow
                  text={t("content.bankDetails.accountNumber")}
                  value={account.bank_account_nr}
                />
                <TableRow
                  text={t("content.bankDetails.sortCode", { number: 1 })}
                  value={account.local_branch_identifier}
                />
                <TableRow
                  text={t("content.bankDetails.sortCode", { number: 2 })}
                  value={account.secondary_branch_identifier}
                />
                {account.available_balance && (
                  <TableRow
                    text={t("content.bankDetails.availableBalance")}
                    value={balanceFormatter(
                      account.available_balance,
                      CurrencyCode.USD
                    )}
                  />
                )}
              </Fragment>
            ))}
            <span className="mb-3">
              {t("content.bankDetails.includeNumber")}
            </span>
            <TableRow
              text={t("content.bankDetails.referenceID")}
              value={transferDetails?.bank_details.ck_number}
            />
          </ContentModal>
          <div className={"flex justify-center mt-20"}>
            <Button onClick={handleOk} className={"uppercase"}>
              {t("common.buttons.ok")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
