import { FC } from "react";
import classNames from "classnames";

interface Props {
  title?: string;
  className?: string;
}

export const ContentModal: FC<Props> = ({ title, className, children }) => {
  return (
    <div
      className={classNames(
        "px-4 py-10 bg-white-100 md:rounded-xl shadow-xl md:px-12",
        className
      )}
    >
      {title && (
        <div className={"text-xl font-medium text-gray-600 mb-8"}>{title}</div>
      )}
      {children}
    </div>
  );
};
