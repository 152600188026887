import { ReactNode, MouseEvent } from "react";
import classNames from "classnames";
import { TransferOptionsStrings } from "../../../PaymentContext";

export interface OptionType {
  label: string;
  value: TransferOptionsStrings;
  icon: ReactNode;
  disable?: boolean;
}

interface OptionProps extends OptionType {
  isActive: boolean;
  onClick: (value: TransferOptionsStrings) => void;
}

export const ProfferedOption = ({
  icon,
  label,
  disable,
  value,
  isActive,
  onClick,
}: OptionProps) => {
  const handleClick = (
    e: MouseEvent<HTMLDivElement>,
    value: TransferOptionsStrings
  ) => {
    e.stopPropagation();
    onClick(value);
  };
  return (
    <div
      role={"button"}
      onClick={(e) => !disable && handleClick(e, value)}
      className={classNames(
        "flex items-center justify-center p-6 border-2 border-gray-300 text-gray-300 rounded-lg cursor-pointer ",
        {
          "text-black-200 border-blue-100": isActive,
        },
        {
          "cursor-not-allowed": disable,
          "hover:text-black-200": !isActive && !disable,
        }
      )}
    >
      <div className={classNames("mr-4", { "text-blue-100": isActive })}>
        {icon}
      </div>
      <div>{label}</div>
    </div>
  );
};
