import queryString from "query-string";
import { env } from "../env";

export enum HttpMethods {
  get = "GET",
  post = "POST",
  put = "PUT",
  delete = "DELETE",
}

export const BASE_URL = env.API;

export type RequestError = { status: number; statusText: string };

interface Params<P> extends Pick<RequestInit, "headers" | "body"> {
  url: string;
  method?: HttpMethods;
  queryParams?: P;
}

export const baseFetch = async <P extends Record<string, unknown>, R>({
  url,
  method = HttpMethods.get,
  headers,
  queryParams,
  body,
}: Params<P>): Promise<R> => {
  const queryUrl = new URL(BASE_URL + url);
  queryUrl.search =
    (method === HttpMethods.get || method === HttpMethods.delete) && queryParams
      ? queryString.stringify(queryParams)
      : "";

  return await fetch(queryUrl.toString(), {
    headers,
    method,
    body,
  }).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      switch (res.status) {
        case 404: {
          return Promise.reject({
            status: res.status,
            statusText: res.statusText || "Transfer details not found for ID",
          });
        }
        default: {
          Promise.reject({
            status: res.status,
            statusText: res.statusText || "Unknown Request Error",
          });
        }
      }
    }
  });
};
