import {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { useHistory } from "react-router";
import queryString from "query-string";
import { useSearchQuery } from "../../core/hooks/useSearchQuery";
import { nameof, typeProxy } from "../../core/helpers/nameof";
import {
  CustomStatus,
  PaymentRequestParams,
  PaymentRetrieveResponse,
  TransferStatus,
} from "./PaymentServices";
import { usePaymentQueries } from "./usePaymentQueries";

const CONTEXT_KEY = "PaymentContext";
export const DETAILS_QUERY_KEY = "PAYMENTS/DETAILS";

export type TransferStatusStrings = keyof typeof TransferStatus | CustomStatus;

export enum TransferOptions {
  bankTransfer = "bankTransfer",
  card = "card",
  crypto = "crypto",
}

export type TransferOptionsStrings = keyof typeof TransferOptions;

interface PaymentState {
  transferStatus: TransferStatusStrings;
  transferType: TransferOptionsStrings;
  dispatch: (action: Actions) => void;
  transferApprove: () => void;
  isLoadingDetails: boolean;
  isLoadingCheckout: boolean;
  transferDetails?: PaymentRetrieveResponse;
  transferPaymentUpdate: (
    bank_account_number: string,
    payment_reference: string
  ) => void;
  isLoadingPaymentUpdate: boolean;
  isSuccessPaymentUpdate: boolean;
}

export const initialState: PaymentState = {
  transferStatus: TransferStatus.PENDING,
  transferType: TransferOptions.bankTransfer,
  dispatch: (_dispatch: Actions) => {},
  transferApprove: () => {},
  isLoadingDetails: false,
  isLoadingCheckout: false,
  transferPaymentUpdate: () => {},
  isLoadingPaymentUpdate: false,
  isSuccessPaymentUpdate: true,
};

export type Actions =
  | {
      type: "SET_TRANSFER_STATUS";
      transferStatus: TransferStatusStrings;
    }
  | {
      type: "SET_TRANSFER_TYPE";
      transferType: TransferOptionsStrings;
    };

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case "SET_TRANSFER_STATUS":
      return { ...state, transferStatus: action.transferStatus };
    case "SET_TRANSFER_TYPE":
      return { ...state, transferType: action.transferType };
    default:
      throw new Error();
  }
};

const PaymentParamsType = typeProxy<PaymentRequestParams>();

export const PaymentContext = createContext<PaymentState>(initialState);

export const PaymentContextProvider: FC = ({ children }) => {
  const searchQuery = useSearchQuery();
  const { push } = useHistory();
  const [state, dispatchAction] = useReducer(reducer, initialState);

  const dispatch = useCallback((action: Actions) => {
    if (process.env.NODE_ENV === "development") {
      console.info("Dispatch Action", {
        context: CONTEXT_KEY,
        ...action,
      });
    }

    dispatchAction(action);
  }, []);

  const searchParams: PaymentRequestParams = useMemo(
    () => ({
      jws: searchQuery.get(nameof(PaymentParamsType.jws)) || "",
    }),
    [searchQuery]
  );

  const {
    transferDetails,
    isLoadingDetails,
    mutateApprove,
    mutatePaymentUpdate,
    isLoadingCheckout,
    isLoadingPaymentUpdate,
    isSuccessPaymentUpdate,
  } = usePaymentQueries({ dispatch, searchParams });

  useEffect(() => {
    if (
      !searchParams.jws
    ) {
      const requestData = {} as PaymentRequestParams;
      requestData.jws =
        prompt("Enter JWS:", "6Q1s7V3vQe7OiLwUYcsUG53frFNfZQxy5ViXRVeA") ||
        "";

      push("/?" + queryString.stringify(requestData));
    }
  }, [push, searchParams]);

  const transferApprove = () => {
    if (!searchParams.jws) {
      return null;
    }

    mutateApprove(searchParams);
  };

  const transferPaymentUpdate = async (
    bank_account_number: string,
    payment_reference: string
  ) => {
    if (!searchParams.jws) {
      return null;
    }

    return mutatePaymentUpdate({
      ...searchParams,
      bank_account_number,
      payment_reference,
    });
  };

  return (
    <PaymentContext.Provider
      value={{
        ...state,
        dispatch,
        transferApprove,
        isLoadingDetails,
        isLoadingCheckout,
        isLoadingPaymentUpdate,
        transferDetails,
        transferPaymentUpdate,
        isSuccessPaymentUpdate,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
