import { CurrencyCode } from "../../modules/payment/PaymentServices";

const _dollarFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const _euroFormatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2,
});

export const balanceFormatter = (
  value: number,
  currency_code: CurrencyCode
) => {
  switch (currency_code) {
    case "USD":
      return _dollarFormatter.format(value);
    case "EUR":
      return _euroFormatter.format(value);
    default:
      return value;
  }
};
