import { useMutation, useQuery } from "react-query";
import {
  CustomStatus,
  paymentApprove,
  paymentUpdate,
  paymentDetails,
  PaymentRequestParams,
  PaymentRetrieveResponse,
  TransferStatus,
  PaymentUpdateRequestParams,
} from "./PaymentServices";
import { RequestError } from "../../api/baseFetch";
import { Actions, DETAILS_QUERY_KEY } from "./PaymentContext";

interface Params {
  dispatch: (action: Actions) => void;
  searchParams: PaymentRequestParams;
}

export const usePaymentQueries = ({ dispatch, searchParams }: Params) => {
  const { data: transferDetails, isLoading: isLoadingDetails } = useQuery<
    PaymentRetrieveResponse | undefined,
    RequestError,
    PaymentRetrieveResponse,
    [string, PaymentRequestParams]
  >([DETAILS_QUERY_KEY, searchParams], paymentDetails, {
    retry: 0,
    onSuccess: (data) => {
      if (!data) {
        return dispatch({
          type: "SET_TRANSFER_STATUS",
          transferStatus: CustomStatus.ERROR,
        });
      }

      dispatch({
        type: "SET_TRANSFER_STATUS",
        transferStatus: data.bank_details.status,
      });
    },
    onError: () => {
      dispatch({
        type: "SET_TRANSFER_STATUS",
        transferStatus: CustomStatus.ERROR,
      });
    },
  });

  const { isLoading: isLoadingCheckout, mutate: mutateApprove } = useMutation(
    (searchParams: PaymentRequestParams) => paymentApprove(searchParams),
    {
      onSuccess: () => {
        dispatch({
          type: "SET_TRANSFER_STATUS",
          transferStatus: TransferStatus.SUCCESS,
        });
      },
      onError: () =>
        dispatch({
          type: "SET_TRANSFER_STATUS",
          transferStatus: CustomStatus.ERROR,
        }),
    }
  );

  const {
    isLoading: isLoadingPaymentUpdate,
    mutateAsync: mutatePaymentUpdate,
    isSuccess: isSuccessPaymentUpdate,
  } = useMutation(
    (params: PaymentUpdateRequestParams) => paymentUpdate(params),
    {
      onError: () => {
        dispatch({
          type: "SET_TRANSFER_STATUS",
          transferStatus: CustomStatus.ERROR,
        });
      },
    }
  );

  return {
    transferDetails,
    isLoadingDetails,
    mutateApprove,
    mutatePaymentUpdate,
    isLoadingPaymentUpdate,
    isSuccessPaymentUpdate,
    isLoadingCheckout,
  };
};
