import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  Fragment,
} from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import ReactMarkdown from "react-markdown";

import { Input } from "src/components/ui/Input";
import { Button } from "src/components/ui/Button";
import { Modal } from "src/components/ui/Modal";
import { useTranslation } from "react-i18next";
import { ContentModal } from "../../ContentModal";
import { PaymentContext } from "../../../PaymentContext";
import { PaymentRetrieveResponse } from "../../../PaymentServices";

import { ReactComponent as FileIcon } from "src/assets/images/file.svg";
import { HorizontalDivider } from "../../../../../components/ui/HorizontalDivider";
import { TableRow } from "../../TableRow";
import { balanceFormatter } from "../../../../../core/helpers/numberFormatters";
import { PAYALL_URL } from "src/core/routes/paths";

export const FormTransferConfirmationDetails = ({
  onCancel,
  onSubmit,
  transferDetails,
}: {
  onCancel: () => void;
  onSubmit: () => void;
  transferDetails?: PaymentRetrieveResponse;
}) => {
  const { t } = useTranslation();

  const {
    isLoadingPaymentUpdate,
    transferPaymentUpdate,
    isSuccessPaymentUpdate,
  } = useContext(PaymentContext);

  const schema = useMemo(() => {
    return yup.object().shape({
      bankName: yup.string().required(t("validations.required")),
      confirmationNumber: yup
        .string()
        .required(t("validations.required"))
        .matches(/^[a-zA-Z0-9]+$/, "Only alphanumeric symbols are allowed"),
    });
  }, [t]);

  const { register, handleSubmit, formState } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      bankName: transferDetails?.bank_account_number,
      confirmationNumber: transferDetails?.payment_reference,
    },
  });

  const [isVisibleCancelModal, setVisibleCancelModal] = useState(false);
  const [isVisibleSuccessModal, setVisibleSuccessModal] = useState(false);

  const onSubmitCallback = async (data: {
    confirmationNumber: string;
    bankName: string;
  }) => {
    try {
      await transferPaymentUpdate(data.bankName, data.confirmationNumber);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isSuccessPaymentUpdate) {
      setVisibleSuccessModal(true);
    }
  }, [isSuccessPaymentUpdate]);

  return (
    <form onSubmit={handleSubmit(onSubmitCallback)} className="w-full">
      <ContentModal
        title={t(
          "formTransferConfirmationDetails.pleaseProvideYourTransferConfirmationDetails"
        )}
        className="shadow-2xl"
      >
        <div className="flex">
          <div className="w-1/2 mr-2">
            <Input
              error={formState.errors?.bankName}
              name="bankName"
              label={t("formTransferConfirmationDetails.bankName")}
              inputRef={register("bankName", { required: true })}
              className={"h-10 border-gray-300"}
              errorPosition="bottom"
            />
          </div>
          <div className="w-1/2 ml-2">
            <Input
              error={formState.errors?.confirmationNumber}
              name="confirmationNumber"
              label={t("formTransferConfirmationDetails.confirmationNumber")}
              inputRef={register("confirmationNumber", { required: true })}
              className={"h-10 border-gray-300"}
              errorPosition="bottom"
            />
          </div>
        </div>
      </ContentModal>

      {transferDetails && transferDetails.bank_details && (
        <>
          <HorizontalDivider className={"mt-10 mb-6"} />
          <TableRow
            text={t("content.payment.modal.total")}
            value={balanceFormatter(
              transferDetails.bank_details?.amount,
              transferDetails.bank_details?.currency_code
            )}
            large={true}
          />
        </>
      )}

      <div className="flex justify-center px-3 mt-12">
        <div className="flex justify-between w-full max-w-2xl">
          <Button
            type="submit"
            className="mr-1"
            isLoading={isLoadingPaymentUpdate}
            disabled={isLoadingPaymentUpdate}
            onClick={onCancel}
          >
            {t("formTransferConfirmationDetails.confirmPayment")}
          </Button>
          <Button
            type="button"
            className="ml-1"
            disabled={isLoadingPaymentUpdate}
            buttonType="secondary"
            onClick={() => {
              setVisibleCancelModal(true);
            }}
          >
            {t("formTransferConfirmationDetails.confirmLater")}
          </Button>
        </div>
      </div>

      <Modal
        isOpen={isVisibleSuccessModal}
        closeModal={() => {
          setVisibleSuccessModal(false);
        }}
        maxWidth="480px"
      >
        <div>
          <div className="flex flex-col items-center">
            <FileIcon />

            <div className="px-5 mt-8 mb-10 text-sm text-center">
              {t("modal.yourTransactionIsNowBeingProcessed")}
            </div>
          </div>

          <div className="text-center">
            <a
              href={
                process.env.REACT_APP_ENV === "production"
                  ? "https://artymys.com/account/order/"
                  : "https://test.artymys.com/account/order/ "
              }
            >
              <Button type="button">Ok</Button>
            </a>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isVisibleCancelModal}
        closeModal={() => {
          setVisibleCancelModal(false);
        }}
        maxWidth="480px"
      >
        <div>
          <div className="mb-6 text-2xl font-medium text-center text-black-100">
            {t("modal.confirmLater")}
          </div>

          <div className="m-auto text-center" style={{ maxWidth: "340px" }}>
            <div className="mb-5 text-sm">
              <p className="mt-0 mb-3">{t("modal.confirmLaterDescription1")}</p>
              <p className="mt-0 mb-3">{t("modal.confirmLaterDescription2")}</p>
            </div>

            <Button
              type="button"
              onClick={() => {
                window.location.replace(PAYALL_URL);
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </Modal>
    </form>
  );
};
