import classNames from "classnames";

interface Props {
  text: string;
  value?: string | number;
  large?: boolean;
}

export const TableRow = ({ text, value, large }: Props) => {
  return value ? (
    <div className={"flex justify-between mb-3"}>
      <div
        className={classNames("text-gray-400 mr-2", {
          "text-xl font-medium text-gray-500 ": large,
        })}
      >
        {text}
      </div>
      <div
        className={classNames("text-right", {
          "text-xl font-medium text-gray-500": large,
        })}
      >
        {value}
      </div>
    </div>
  ) : null;
};
