import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Title } from "../Title";
import { ContentModal } from "../ContentModal";
import { Button } from "../../../../components/ui/Button";
import { ReactComponent as FileIcon } from "../../../../assets/images/file.svg";
import { PaymentContext } from "../../PaymentContext";
import { PAYALL_URL } from "../../../../core/routes/paths";
import { Header } from "../../../../components/layout/Header";

export const Complete = () => {
  const { t } = useTranslation();
  const { transferDetails } = useContext(PaymentContext);

  const handleContinue = () => {
    window.location.replace(PAYALL_URL);
  };

  return (
    <>
      <Header />
      <div className={"flex-grow my-20"}>
        <div>
          <Title>{t("content.complete.title")}</Title>
          <ContentModal className={"py-14"}>
            <div className={"flex justify-center items-center flex-col"}>
              <FileIcon className={"mb-14"} />
              <span>{t("content.complete.modal.part1")}</span>
              <span>
                {t("content.complete.modal.part2")}&nbsp;
                <strong>{transferDetails?.bank_details.batch_id}</strong>
              </span>
            </div>
          </ContentModal>
          <div className={"flex justify-center mt-20"}>
            <Button onClick={handleContinue} className={"uppercase"}>
              {t("common.buttons.continue")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
