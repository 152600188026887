import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FieldError, FieldErrors } from 'react-hook-form';

export interface LabelProps {
  name?: string;
  className?: string;
  errorPosition?: 'bottom' | undefined;
  label?: string;
  labelFontClassName?: string;
  labelColorClassName?: string;
  optional?: boolean;
  error?: FieldError | FieldErrors;
}

export const Label: FC<LabelProps> = ({
  name,
  className,
  errorPosition,
  label,
  labelFontClassName = 'text-xs font-me',
  labelColorClassName = 'text-gray-400',
  error,
  optional,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <label
      htmlFor={name}
      className={classNames('relative block', className, { error })}
    >
      <span
        className={classNames(
          'tracking-wide',
          labelColorClassName,
          labelFontClassName
        )}
      >
        {label}
      </span>
      {error && (
        <span
          className={classNames('text-xs text-red-500 pr-3', {
            'absolute right-0 mt-1': !errorPosition,
            'absolute bottom-0 left-0 -mb-5': errorPosition === 'bottom',
          })}
        >
          {error?.message}
        </span>
      )}
      {!error && optional && (
        <span className="absolute right-0 mt-1 text-xxs font-medium text-gray-500">
          {t('form.optional')}
        </span>
      )}
      {children}
    </label>
  );
};
