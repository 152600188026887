import { FC } from "react";
import classNames from "classnames";

interface Props {
  className?: string;
}

export const Title: FC<Props> = ({ className, children }) => {
  return (
    <h1
      className={classNames(
        "text-black-100 text-center text-2xl font-medium mb-14",
        className
      )}
    >
      {children}
    </h1>
  );
};
