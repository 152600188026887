import { ReactNode, useContext, useEffect } from "react";
import { PaymentContext } from "./PaymentContext";
import { AccountProblem } from "./components/Content/AccountProblem";
import { InsufficientFunds } from "./components/Content/InsufficientFunds";
import { Complete } from "./components/Content/Complete";
import { Error } from "./components/Content/Error";
import { Checkout } from "./components/Content/Payment/Checkout";
import { Loader } from "../../components/ui/Loader";
import { CustomStatus, TransferStatus } from "./PaymentServices";

const contentItems: { [keyof in TransferStatus | CustomStatus]: ReactNode } = {
  [TransferStatus.PENDING]: <Checkout />,
  [TransferStatus.SUCCESS]: <Complete />,
  [TransferStatus.INSUFFICIENT_FUNDS]: <InsufficientFunds />,
  [TransferStatus.SOURCE_ACCOUNT_NOT_FOUND]: <AccountProblem />,
  [TransferStatus.ACCOUNT_SUSPENDED]: <AccountProblem />,
  [CustomStatus.ERROR]: <Error />,
};

export const PaymentPage = () => {
  const { transferStatus, isLoadingDetails } = useContext(PaymentContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [transferStatus]);

  return (
    <Loader isLoading={isLoadingDetails}>{contentItems[transferStatus]}</Loader>
  );
};
