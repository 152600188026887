import React, { ReactNode, Ref, FormEvent, KeyboardEventHandler } from "react";
import classNames from "classnames";
import { Control, FieldError } from "react-hook-form";
import { Label, LabelProps } from "./FormLabel";

interface InputProps extends LabelProps {
  name: string;
  inputRef?: any;
  type?: "text" | "password" | "textarea";
  icon?: ReactNode;
  error?: FieldError;
  hasError?: boolean;
  placeholder?: string;
  className?: string;
  maxLength?: number;
  disabled?: boolean;
  optional?: boolean;
  control?: Control;
  rules?: any;
  onChange?: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyPress?: KeyboardEventHandler;
}

const getInputProps = ({
  type,
  error,
  hasError,
  className,
  placeholder,
  name,
  disabled = false,
}: Pick<
  InputProps,
  | "type"
  | "error"
  | "hasError"
  | "className"
  | "placeholder"
  | "name"
  | "disabled"
>) => ({
  id: name,
  name: name,
  placeholder,
  className: classNames(
    "block w-full mt-1 form-input text-ssm border px-3 focus:border-blue-500 outline-none rounded",
    {
      "border-red-500 focus:border-red-500 focus:shadow-outline-red":
        !!error || hasError,
      "h-18 text-xs": type === "textarea",
      "bg-gray-300 text-gray-700": disabled,
    },
    className
  ),
});

export const Input = ({
  label,
  disabled = false,
  labelFontClassName,
  labelColorClassName,
  icon,
  name,
  inputRef,
  error,
  hasError,
  type = "text",
  placeholder = " ",
  className,
  maxLength,
  optional,
  errorPosition,
  onKeyPress,
  onChange,
}: InputProps) => {
  const inputProps = {
    ...getInputProps({
      type,
      error,
      hasError,
      className,
      placeholder,
      name,
      disabled,
    }),
  };

  return (
    <Label
      name={name}
      label={label}
      labelFontClassName={labelFontClassName}
      labelColorClassName={labelColorClassName}
      error={error}
      errorPosition={errorPosition}
      optional={optional}
    >
      <div className="relative">
        {type === "textarea" ? (
          <textarea
            style={{ resize: "none" }}
            maxLength={maxLength}
            disabled={disabled}
            onChange={onChange}
            onKeyPress={onKeyPress}
            {...inputProps}
            {...inputRef}
          />
        ) : (
          <input
            type={type}
            maxLength={maxLength}
            disabled={disabled}
            onChange={onChange}
            onKeyPress={onKeyPress}
            {...inputProps}
            {...inputRef}
          />
        )}
        {icon && (
          <span className="absolute top-0 right-0 flex items-center justify-center w-12 h-full">
            {icon}
          </span>
        )}
      </div>
    </Label>
  );
};
