import React, { Fragment, useContext, useState } from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Title } from "../../Title";
import { ContentModal } from "../../ContentModal";
import { TableRow } from "../../TableRow";
import { HorizontalDivider } from "../../../../../components/ui/HorizontalDivider";
import { balanceFormatter } from "../../../../../core/helpers/numberFormatters";
import { Button } from "../../../../../components/ui/Button";
import { ReactComponent as CastleIcon } from "../../../../../assets/images/castle-2.svg";
import { ProfferedSelect } from "./ProfferedSelect";
import { PaymentContext } from "../../../PaymentContext";
import { FormTransferConfirmationDetails } from "./FormTransferConfirmationDetails";
import { Header } from "../../../../../components/layout/Header";

export const Checkout = () => {
  const { t } = useTranslation();
  const { isLoadingCheckout, transferDetails } = useContext(PaymentContext);

  const [step, setStep] = useState(1);

  return (
    <>
      <Header
        onClickBack={
          step === 2
            ? () => {
                setStep(1);
              }
            : undefined
        }
      />
      <div className={"flex-grow my-20"}>
        <div>
          {step === 1 && (
            <>
              <Title>{t("content.payment.title")}</Title>
              <ContentModal title={t("content.payment.modal.title")}>
                {transferDetails?.bank_details.created_at && (
                  <TableRow
                    text={t("content.payment.modal.date")}
                    value={format(
                      new Date(transferDetails.bank_details.created_at),
                      "PPpp"
                    )}
                  />
                )}
                <TableRow
                  text={t("content.payment.modal.referenceID")}
                  value={transferDetails?.bank_details.order_reference}
                />
                <HorizontalDivider className={"my-6"} />
                {transferDetails?.bank_details.amount && (
                  <TableRow
                    text={t("content.payment.modal.total")}
                    value={balanceFormatter(
                      transferDetails.bank_details.amount,
                      transferDetails.bank_details.currency_code
                    )}
                    large={true}
                  />
                )}
              </ContentModal>
              <ProfferedSelect />
              <div className={"flex justify-center"}>
                <Button
                  // onClick={transferApprove}
                  onClick={() => {
                    setStep(2);
                  }}
                  className={"uppercase"}
                  buttonPrefix={<CastleIcon />}
                  isLoading={isLoadingCheckout}
                >
                  {t("common.buttons.payNow")}
                </Button>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <Title>{t("content.payment.payNow")}</Title>
              <p className="text-center -mt-8 mb-8 max-w-2xl mx-auto">
                {t("content.payment.payNowDescription")}
              </p>
              <ContentModal title={t("content.payment.payNowModal.title")}>
                {transferDetails?.bank_details.bank_accounts.map((account) => (
                  <Fragment key={account.bank_account_nr}>
                    <TableRow
                      text={t("content.payment.payNowModal.bankName")}
                      value={account?.bank_name}
                    />
                    <TableRow
                      text={t("content.payment.payNowModal.bankAddress")}
                      value={`${account?.address1} ${account?.address2} ${account?.address3}`}
                    />
                    <TableRow
                      text={t("content.payment.payNowModal.bankAccount")}
                      value={`${account?.bank_account_nr}`}
                    />
                    <TableRow
                      text={t("content.payment.payNowModal.bank")}
                      value={`${account?.local_branch_identifier}`}
                    />
                    <TableRow
                      text={t("content.payment.payNowModal.swift")}
                      value={`${account?.swift}`}
                    />
                    <TableRow
                      text={t("content.payment.payNowModal.iban")}
                      value={`${account?.iban}`}
                    />
                  </Fragment>
                ))}
                <HorizontalDivider className={"my-6"} />
                <TableRow
                  text={t("content.payment.payNowModal.referenceID")}
                  value={transferDetails?.bank_details.ck_number}
                />
                <span className="mb-3">
                  {t("content.bankDetails.includeNumber")}
                </span>
              </ContentModal>

              <div className="mt-6">
                <FormTransferConfirmationDetails
                  onCancel={() => {}}
                  onSubmit={() => {}}
                  transferDetails={transferDetails}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
