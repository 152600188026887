import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OptionType, ProfferedOption } from "./ProfferedOption";
import { ReactComponent as PayBankIcon } from "../../../../../assets/images/pay-bank.svg";
import { ReactComponent as PayCardIcon } from "../../../../../assets/images/pay-card.svg";
import { ReactComponent as PayCryptoIcon } from "../../../../../assets/images/pay-crypto.svg";
import {
  PaymentContext,
  TransferOptions,
  TransferOptionsStrings,
} from "../../../PaymentContext";

export const ProfferedSelect = () => {
  const { t } = useTranslation();
  const { dispatch, transferType } = useContext(PaymentContext);

  const handleChange = (paymentType: TransferOptionsStrings) => {
    dispatch({ type: "SET_TRANSFER_TYPE", transferType: paymentType });
  };

  const options: OptionType[] = useMemo(
    () => [
      {
        icon: <PayCardIcon className={"fill-current"} />,
        label: t("content.payment.selectPreferredPay.bankTransfer"),
        value: TransferOptions.bankTransfer,
      },
      {
        icon: <PayBankIcon className={"fill-current"} />,
        label: t("content.payment.selectPreferredPay.card"),
        value: TransferOptions.card,
        disable: true,
      },
      {
        icon: <PayCryptoIcon className={"fill-current"} />,
        label: t("content.payment.selectPreferredPay.crypto"),
        value: TransferOptions.crypto,
        disable: true,
      },
    ],
    [t]
  );

  return (
    <div className={"mt-14 mb-20 px-4 md:px-12"}>
      <div className={"text-xl font-medium text-gray-500 mb-8"}>
        {t("content.payment.selectPreferredPay.title")}
      </div>
      <div className={"grid gap-4 md:gap-7 md:grid-cols-3"}>
        {options.map((option) => (
          <ProfferedOption
            key={option.value}
            isActive={option.value === transferType}
            onClick={handleChange}
            {...option}
          />
        ))}
      </div>
    </div>
  );
};
