import React, { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Title } from "../Title";
import { ContentModal } from "../ContentModal";
import { Button } from "../../../../components/ui/Button";
import { TableRow } from "../TableRow";
import { HorizontalDivider } from "../../../../components/ui/HorizontalDivider";
import { PaymentContext } from "../../PaymentContext";
import { PAYALL_URL } from "../../../../core/routes/paths";
import { Header } from "../../../../components/layout/Header";

export const AccountProblem = () => {
  const { t } = useTranslation();
  const { transferDetails } = useContext(PaymentContext);

  const handleOk = () => {
    window.location.replace(PAYALL_URL);
  };

  return (
    <>
      <Header />
      <div className={"flex-grow my-20"}>
        <div>
          <div
            className={
              "flex flex-col justify-center items-center w-full max-w-3xl mx-auto px-4"
            }
          >
            <Title>{t("content.accountProblem.title")}</Title>
            <span className={"text-center mb-12"}>
              {t("content.accountProblem.text")}
            </span>
          </div>
          <ContentModal
            className={"py-14"}
            title={t("content.bankDetails.title")}
          >
            {transferDetails?.bank_details.bank_accounts.map((account) => (
              <Fragment key={account.bank_account_nr}>
                <TableRow
                  text={t("content.bankDetails.routingNumber")}
                  value={account?.aba_routing_nr}
                />
                <TableRow
                  text={t("content.bankDetails.accountNumber")}
                  value={account?.bank_account_nr}
                />
                <HorizontalDivider className={"my-6"} />
              </Fragment>
            ))}
            <TableRow
              text={t("content.bankDetails.referenceID")}
              value={transferDetails?.bank_details.order_reference}
            />
            {t("content.bankDetails.includeNumber")}
          </ContentModal>
          <div className={"flex justify-center mt-20"}>
            <Button onClick={handleOk} className={"uppercase"}>
              {t("common.buttons.ok")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
