import {QueryClient, QueryClientProvider} from "react-query";
import Modal from 'react-modal';

import { Layout } from "./components/layout/Layout";
import { PaymentPage } from "./modules/payment/PaymentPage";
import { PaymentContextProvider } from "./modules/payment/PaymentContext";
import { Route, Switch } from "react-router";
import { paths } from "./core/routes/paths";

Modal.setAppElement('#root');

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

function App() {

  return (
    <Switch>
      <Route path={paths.payPage.main} exact>
        <QueryClientProvider client={queryClient}>
          <Layout>
            <PaymentContextProvider>
              <PaymentPage />
            </PaymentContextProvider>
          </Layout>
        </QueryClientProvider>
      </Route>
    </Switch>
  );
}

export default App;
