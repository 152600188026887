import { ReactNode, PropsWithoutRef } from "react";
import classNames from "classnames";

type ButtonType = "link" | "secondary";

const getTypeStyle = (type?: ButtonType) => {
  switch (type) {
    case "link":
      return "bg-transparent text-blue-100";
    case "secondary":
      return "bg-transparent border-gray-350 border-2 text-gray-350 rounded-md w-full max-w-xsm";
    default:
      return "button";
  }
};

interface Props extends PropsWithoutRef<JSX.IntrinsicElements["button"]> {
  buttonType?: ButtonType;
  buttonPrefix?: ReactNode | string;
  isLoading?: boolean;
}

export const Button = ({
  buttonType,
  buttonPrefix,
  className,
  isLoading,
  disabled,
  children,
  ...rest
}: Props) => {
  const _prefix =
    buttonPrefix || isLoading ? (
      <span className={"mr-4 h-5 w-5 flex items-center"}>
        {isLoading ? (
          <svg className="animate-spin" viewBox="0 0 24 24" fill={"none"}>
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        ) : (
          buttonPrefix
        )}
      </span>
    ) : null;

  return (
    <button
      type={"button"}
      className={classNames(
        "focus:outline-none p-3 hover:opacity-90",
        { "flex items-center justify-center": !!_prefix },
        { "cursor-not-allowed": isLoading },
        getTypeStyle(buttonType),
        className
      )}
      disabled={disabled || isLoading}
      {...rest}
    >
      {_prefix}
      <span>{children}</span>
    </button>
  );
};
