import React, { FC } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";

export const Layout: FC = ({ children }) => {
  return (
    <div className="flex relative min-h-screen">
      <div className={"absolute top-0 left-0 w-full h-1/2  bg-gray-100 "} />
      <div
        className={
          "flex flex-col max-w-4xl mx-auto my-4 w-full z-10 md:py-11 md:px-4 lg:px-0"
        }
      >
        {children}
        <Footer />
      </div>
    </div>
  );
};
